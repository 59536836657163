'use strict';
import { useParams, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    Box,
    Tooltip,
    TextField,
    FormControl,
    MenuItem,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import ActionButtonEdit from 'components/Entrada/EDIT/ActionButtonEdit';
import ActionButtonsEditCreate from 'components/Entrada/EDIT/ActionButtonEditCreate';
const { v4: uuidv4 } = require('uuid');
// ============================= INITIALSTATE =============================
const initialState = {
    documento: '',
    emissao: '',
    movimento: new Date(),
    fornecedor: 0,
    observacao: '',
    itens: []
};

const EditarEntrada = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    // ============================= STATES =============================
    const [campos, setCampos] = useState(initialState);
    const [openCreate, setOpenCreate] = useState(false);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [listAllFornecedor, setListAllFornecedor] = useState([]);
    const [dataForSubmit, setDataForSubmit] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const columns = [
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 3 },
        { field: 'unidade', headerName: 'UN', flex: 2 },
        { field: 'quantidade', headerName: 'QNTD', flex: 1 },
        { field: 'valor_unitario', headerName: 'VALOR UN.', flex: 1 },
        { field: 'valor_total', headerName: 'VALOR T.', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtonEdit
                    status={campos.status}
                    setCampos={setCampos}
                    itens={itens}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />
            )
        }
    ];

    const somaValorTotal = itens.reduce((acumulador, objeto) => {
        return acumulador + Number(objeto.valor_total);
    }, 0);

    // ============================= FUNÇÕES =============================
    const atualizarCampo = (campo, valor) => {
        setCampos((prev) => ({ ...prev, [campo]: valor }));
    };

    const adicionarCampoItem = () => {
        setCampos((prevCampos) => ({
            ...prevCampos,
            itens: [
                ...prevCampos.itens,
                {
                    action: 'create',
                    item_id: '',
                    descricao: '',
                    quantidade: 1,
                    unidade: '',
                    valor_unitario: 0,
                    valor_total: 0,
                    almoxarifado_id: '',
                    localizacao_id: '',
                    observacao: ''
                }
            ]
        }));
    };

    const removerCampoItem = (uuid) => {
        setItens((prevItens) => {
            const novosItens = prevItens.filter((item) => item.uuid !== uuid);
            return novosItens;
        });

        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            const indiceItem = novosCampos.itens.findIndex((item) => item.uuid === uuid);

            if (indiceItem !== -1) {
                const item = novosCampos.itens[indiceItem];
                item.action = 'delete';
            }

            return novosCampos;
        });

        // Fechar modal
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = async (uuid, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens[campos?.itens.length - 1][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleOpenAndCreateCampoItem = () => {
        setOpenCreate(true);
        adicionarCampoItem();
    };

    const getAllData = async () => {
        try {
            const [responseAlmoxarifado, responseLocalizacao, responseItens, responseFornecedor] = await Promise.all([
                api.getAllAlmoxarifado(),
                api.getAllLocalizacao(),
                api.getAllItens(),
                api.getAllFornecedor()
            ]);
            setListAllAlmoxarifado(responseAlmoxarifado.data.almoxarifados);
            setListAllLocalizacao(responseLocalizacao.data.localizacao);
            setListAllItens(responseItens.data.itens);
            setListAllFornecedor(responseFornecedor.data.fornecedores);
        } catch (error) {
            toast.error(error?.message);
        }
    };

    const getEntradaById = async (entradaId) => {
        const response = await api.getEntradaEstoqueById(entradaId);
        // ==== montando objeto de cada item ====
        const data = response.data;
        data.itens.forEach((item, index) => {
            item.uuid = uuidv4();
            item.descricao = item.item.descricao;
            item.unidade = item.item.unidade_medida.descricao;
            item.action = 'update';
            delete item.item;
        });
        // ==== montando body de campos ====
        const objeto = {
            id: data.id,
            documento: data.numero_documento,
            observacao: data.observacao,
            emissao: data.data_emissao,
            movimento: data.data_entrada,
            fornecedor: data.fornecedor_id,
            status: data.status,
            itens: data.itens
        };
        setCampos(objeto);
        setItens(data.itens);
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prev) => ({ ...prev, movimento: currentDate }));
    };

    useEffect(() => {
        getAllData();
        getDate();
        getEntradaById(id);
    }, []);

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();

        try {
            // Filtra itens com ação 'delete' ou descrição vazia
            const itensFiltrados = campos.itens.filter((item) => item.action !== 'delete' && item.descricao !== '');

            // Atualiza a ação dos itens no estado 'itens'
            const novosItens = itens.map((item) => {
                const itemCampos = itensFiltrados.find((itemCampo) => itemCampo.uuid === item.uuid);

                if (itemCampos) {
                    item.action = itemCampos.action;
                }

                // Remove 'id' se a ação for 'create'
                if (item.action === 'create') {
                    delete item.id;
                }

                return item;
            });

            // Atualiza os itens deletados
            novosItens.push(...campos.itens.filter((item) => item.action === 'delete'));

            // Envia a requisição para atualizar a entrada de estoque
            await api.updateEntradaEstoque(campos.id, campo, novosItens);

            // Reseta os estados e fecha o modal
            setCampos({
                documento: '',
                emissao: '',
                movimento: new Date(),
                fornecedor: 0,
                observacao: '',
                itens: []
            });
            setItens([]);
            setOpenCreate(false);
            navigate('/main/entrada');
            toast.success('Entrada cadastrada');
        } catch (error) {
            toast.error(`Erro ao editar entrada: ${error.response.data.error}`);
        }
    };

    return (
        <MainCard title="Dados da Entrada">
            <Grid container spacing={gridSpacing}>
                <ActionButtonsEditCreate
                    open={openCreate}
                    handleClose={handleClose}
                    itens={itens}
                    setItens={setItens}
                    campos={campos}
                    setCampos={setCampos}
                    adicionarCampoItem={adicionarCampoItem}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1em',
                                    width: '100%',
                                    marginBottom: '1em'
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        label="Nota"
                                        disabled
                                        value={campos.documento}
                                        onChange={(e) => atualizarCampo('documento', e.target.value)}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        type="date"
                                        label="Data emissão"
                                        disabled
                                        value={campos.emissao}
                                        onChange={(e) => atualizarCampo('emissao', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        type="date"
                                        label="Movimento"
                                        value={campos.movimento}
                                        disabled
                                        onChange={(e) => atualizarCampo('movimento', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label="Fornecedor"
                                        disabled
                                        value={campos.fornecedor}
                                        onChange={(e) => atualizarCampo('fornecedor', e.target.value)}
                                    >
                                        {listAllFornecedor.map((fornecedor) => (
                                            <MenuItem key={fornecedor.id} value={fornecedor.id}>
                                                {fornecedor.nome_fantasia}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl sx={{ width: '50%' }}>
                                    <TextField
                                        multiline
                                        minRows={3}
                                        label="Observação"
                                        placeholder="Observação"
                                        disabled
                                        value={campos.observacao}
                                        onChange={(e) => atualizarCampo('observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                <Box>
                                    <Typography variant="h4">Relação de itens</Typography>
                                </Box>
                                <Box>
                                    <Tooltip title="Adicionar item">
                                        <Button
                                            variant="contained"
                                            color="success"
                                            disabled
                                            onClick={(e) => handleOpenAndCreateCampoItem()}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                <DataGrid
                                    rows={itens}
                                    columns={columns}
                                    hideFooter={true}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                    getRowId={(row) => row.uuid}
                                />

                                <Box
                                    sx={{
                                        width: '40%',
                                        marginLeft: 'auto',
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <h4>Valor Total:</h4>
                                        <Box
                                            sx={{
                                                border: '1px solid #ccc',
                                                padding: '8px 16px',
                                                borderRadius: 2
                                            }}
                                        >
                                            {somaValorTotal.toFixed(2)}
                                        </Box>
                                    </Box>

                                    <Tooltip title="Salvar entrada">
                                        <Button
                                            variant="contained"
                                            color="success"
                                            disabled
                                            onClick={(e) => {
                                                handleSubmit(e, campos);
                                            }}
                                        >
                                            <SaveIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarEntrada;
