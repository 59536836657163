import { Button, Box, Typography, FormControl, TextField, Dialog, Autocomplete, Modal } from '@mui/material';
import { useApi } from 'Service/axios';
import { CustomFlexBox } from './style';
import { DataGrid } from '@mui/x-data-grid';
import ActionButton from '../ActionButton';
import { useState } from 'react';
import { toast } from 'react-toastify';
import PdfBaixaManual from 'components/PDF/Estoque/PdfBaixaManual';
import { PDFViewer } from '@react-pdf/renderer';
const stylePdf = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '90vh'
};

const FilterDrawer = ({ open, setOpen, itens, saidas, setBaixa, handleChangeLocations }) => {
    // ============================= INSTÂNCIAS ==========================
    const api = useApi();
    // ============================= STATES ==============================
    const [filtredValues, setFiltredValues] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [saidaPdfData, setSaidaPdfData] = useState(null);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [selectedDate, setSelectedDate] = useState({ firstDate: '', lastDate: '' });
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.3 },
        { field: 'item', headerName: 'Item', flex: 2, valueGetter: (params) => params.row.item.descricao },
        { field: 'data', headerName: 'Data', flex: 1, valueGetter: (params) => formatarDataParaString(params.row.data) },
        { field: 'quantidade', headerName: 'Quantidade', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButton
                    row={params.row}
                    setBaixa={setBaixa}
                    setOpen={setOpen}
                    resetStates={resetStates}
                    handleChangeLocations={handleChangeLocations}
                    getBaixaDataById={getBaixaDataById}
                />
            )
        }
    ];
    // ============================= FUNÇÕES =============================
    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const formatarDataParaString = (dateValue) => {
        let data = new Date(dateValue);
        const dia = (data.getDate() + 1).toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear().toString();

        return `${dia}/${mes}/${ano}`;
    };

    const handleChangeFirstDate = (value) => {
        setSelectedDate({ ...selectedDate, firstDate: value });
    };

    const handleChangeLastDate = (value) => {
        setSelectedDate({ ...selectedDate, lastDate: value });
    };

    const resetStates = () => {
        setOpen(false);
        setFiltredValues([]);
        setSelectedItem('');
        setSelectedDate({ firstDate: '', lastDate: '' });
    };

    const handleFilterValues = (values) => {
        let filtredItens;
        let firstDate = selectedDate.firstDate;
        let lastDate = selectedDate.lastDate;
        switch (true) {
            case values === '':
                toast.error('Deve ser informado um item');
                break;
            case selectedDate.firstDate === '' && selectedDate.lastDate === '':
                filtredItens = saidas.filter((item) => {
                    return item.item_id === values;
                });
                setFiltredValues(filtredItens);
                break;
            case selectedDate.firstDate !== '' && selectedDate.lastDate === '':
                filtredItens = saidas.filter((item) => {
                    return item.item_id === values && item.data >= firstDate;
                });
                setFiltredValues(filtredItens);
                break;
            case selectedDate.firstDate === '' && selectedDate.lastDate !== '':
                filtredItens = saidas.filter((item) => {
                    return item.item_id === values && item.data <= lastDate;
                });
                setFiltredValues(filtredItens);
                break;
            case selectedDate.firstDate !== '' && !selectedDate.lastDate !== '':
                filtredItens = saidas.filter((item) => {
                    return item.item_id === values && item.data >= firstDate && item.data <= lastDate;
                });
                setFiltredValues(filtredItens);
                break;
            default:
                toast.error('Erro ao filtrar, tente novamente');
                break;
        }
    };

    async function getBaixaDataById(id) {
        try {
            const { data } = await api.getSaidaByIdWithItems(id);
            setSaidaPdfData(data);
            setOpenModalPdf(true);
        } catch (error) {
            toast.error(`Erro ao buscar dados da baixa: ${error?.message}`);
        }
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'}>
            <Modal
                open={openModalPdf}
                onClose={() => {
                    setOpenModalPdf(false);
                }}
            >
                <Box sx={stylePdf}>
                    <PDFViewer width={'100%'} height={'100%'}>
                        <PdfBaixaManual data={saidaPdfData} />
                    </PDFViewer>
                </Box>
            </Modal>
            <Box sx={{ padding: '1em', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: '100%' }}>
                    <CustomFlexBox>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={itens}
                                getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                                value={itens.find((item) => item.id === selectedItem) || null}
                                onChange={(e, newValue) => setSelectedItem(newValue.id)}
                                renderInput={(params) => <TextField {...params} label="Item" />}
                            />
                        </FormControl>
                    </CustomFlexBox>
                    <CustomFlexBox flex={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'1em'}>
                        <FormControl fullWidth>
                            <TextField
                                type="date"
                                label="De"
                                value={selectedDate.firstDate}
                                onChange={(e) => handleChangeFirstDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                        <Typography variant="body">Até</Typography>
                        <FormControl fullWidth>
                            <TextField
                                type="date"
                                label="Até"
                                value={selectedDate.lastDate}
                                onChange={(e) => handleChangeLastDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </CustomFlexBox>
                    <CustomFlexBox flex={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={() => handleFilterValues(selectedItem)}>FILTRAR</Button>
                    </CustomFlexBox>
                </Box>
                <Box sx={{ height: '300px' }}>
                    <DataGrid
                        rows={filtredValues}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </Box>
            </Box>
        </Dialog>
    );
};

export default FilterDrawer;
