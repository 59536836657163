import { Autocomplete, Button, FormControl, Grid, MenuItem, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PDFViewer } from '@react-pdf/renderer';
import PdfSolicitacao from 'components/PDF/Relatorios/PDFSolicitacao';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
const stylePdf = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '90vh'
};

const RelatorioSolicitacao = () => {
    const api = useApi();
    const [dadosFiltro, setDadosFiltro] = useState({ obraId: '', projetoId: '', dataInicial: '', dataFinal: '' });
    const [obras, setObras] = useState([]);
    const [projetos, setProjetos] = useState([]);
    const [pdfData, setPdfData] = useState(null);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [loading, setLoading] = useState(false);
    const projetosFiltrados = projetos.filter((projeto) => projeto.obra_id === dadosFiltro.obraId);

    async function sendDataToPDF(dadosFiltro) {
        if (!dadosFiltro.obraId || !dadosFiltro.dataInicial || !dadosFiltro.dataFinal) {
            return toast.error('Obra, Data Inicial e Data Final são campos obrigatórios para o filtro');
        }
        try {
            setLoading(true);
            const { data } = await api.getRelatorioSolicitacao(
                dadosFiltro.obraId,
                dadosFiltro.projetoId,
                dadosFiltro.dataInicial,
                dadosFiltro.dataFinal
            );

            setPdfData({
                ...data,
                dadosFiltro
            });
            setOpenModalPdf(true);
        } catch (error) {
            toast.error('Erro ao buscar dados para o relatório');
        } finally {
            setLoading(false);
        }
    }

    async function getData() {
        try {
            const responseObras = await api.getAllObra();
            const responseProjetos = await api.getAllProjeto();
            setObras(responseObras?.data?.obras);
            setProjetos(responseProjetos?.data?.projetos);
        } catch (error) {
            toast.error('Erro ao buscar dados para o filtro');
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <MainCard title="Relatório de Solicitação">
            <Grid container spacing={gridSpacing}>
                {/* FORM */}
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ width: '70%', display: 'flex', gap: '1rem', margin: '0 auto', marginTop: '1rem' }}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={obras}
                                getOptionLabel={(option) => `${option.id}-${option.descricao}`}
                                value={obras.find((obra) => obra.id === dadosFiltro.obraId) || null}
                                onChange={(e, newValue) => {
                                    setDadosFiltro({ ...dadosFiltro, obraId: newValue ? newValue.id : '' });
                                }}
                                renderInput={(params) => <TextField {...params} label="Obra" />}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={projetosFiltrados}
                                getOptionLabel={(option) => `${option.id}-${option.descricao}`}
                                value={projetosFiltrados.find((projeto) => projeto.id === dadosFiltro.projetoId) || null}
                                onChange={(e, newValue) => {
                                    setDadosFiltro({ ...dadosFiltro, projetoId: newValue ? newValue.id : '' });
                                }}
                                disabled={!dadosFiltro.obraId}
                                renderInput={(params) => <TextField {...params} label="Projeto" />}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: '70%', display: 'flex', gap: '1rem', margin: '0 auto', marginTop: '1rem' }}>
                        <FormControl fullWidth>
                            <TextField
                                label="Data Inicial"
                                type="date"
                                value={dadosFiltro.dataInicial}
                                onChange={(e) => setDadosFiltro({ ...dadosFiltro, dataInicial: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Data Final"
                                type="date"
                                value={dadosFiltro.dataFinal}
                                onChange={(e) => setDadosFiltro({ ...dadosFiltro, dataFinal: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: '70%', display: 'flex', margin: '0 auto', marginTop: '1rem', justifyContent: 'flex-end' }}>
                        <Button variant="contained" onClick={() => sendDataToPDF(dadosFiltro)} disabled={loading}>
                            {loading ? 'Aguarde...' : 'Confirmar'}
                        </Button>
                    </Box>
                </Box>
                {/* PDF */}
                <Modal
                    open={openModalPdf}
                    onClose={() => {
                        setOpenModalPdf(false);
                    }}
                >
                    <Box sx={stylePdf}>
                        <PDFViewer width={'100%'} height={'100%'}>
                            <PdfSolicitacao data={pdfData} />
                        </PDFViewer>
                    </Box>
                </Modal>
            </Grid>
        </MainCard>
    );
};

export default RelatorioSolicitacao;
