import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    Typography,
    MenuItem
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

import InputMask from 'react-input-mask';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Context } from 'context/UserContext';

// components
import { FormBox } from './style';
import { useContext } from 'react';

// utils
import { removeMask } from 'utils/removeMask';
import { toast } from 'react-toastify';

const UserRegisterForm = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [strength, setStrength] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [level, setLevel] = useState();

    const { registerUser } = useContext(Context);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        changePassword('123456');
    }, []);

    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['nome', 'usuario', 'email', 'setor', 'telefone', 'password'];

        for (const field of fieldsToCheck) {
            if (values[field].trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleRegister = async (values) => {
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // monta o payload final
        const user = filteredValues;

        try {
            await registerUser(user);
        } catch (error) {
            toast.error('Erro ao cadastrar usuário, verifique os dados cadastrados');
        }
    };

    return (
        <Formik
            initialValues={{
                nome: '',
                usuario: '',
                email: '',
                setor: '',
                telefone: '',
                password: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                nome: Yup.string().required('Nome é obrigatorio'),
                email: Yup.string().email('tem que ser um email valido').max(100).required('Email é obrigatorio'),
                setor: Yup.string().required('Setor é obrigatório'),
                telefone: Yup.string()
                    .matches(/^\([1-9]{2}\) [9]{0,1}[0-9]{4}-[0-9]{4}$/, 'Telefone inválido')
                    .required('O telefone é obrigatório'),
                password: Yup.string()
                    .matches(
                        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/,
                        'A senha deve conter 6 caracteres, alfanuméricos com pelo menos um caractere especial'
                    )
                    .required('Senha é obrigatória')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    values.telefone = removeMask(values.telefone);

                    handleRegister(values);
                } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormBox>
                        <FormControl fullWidth error={Boolean(touched.nome && errors.nome)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-nome-register">Nome</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-nome-register"
                                type="text"
                                value={values.nome}
                                name="nome"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.nome && errors.nome && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.nome}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl fullWidth error={Boolean(touched.usuario && errors.usuario)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-usuario-register">Usuário</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-usuario-register"
                                type="text"
                                value={values.usuario}
                                name="usuario"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.usuario && errors.usuario && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.usuario}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.telefone && errors.telefone)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-telefone-register">Celular</InputLabel>
                            <InputMask mask="(99) 99999-9999" value={values.telefone} onBlur={handleBlur} onChange={handleChange}>
                                {(inputProps) => (
                                    <OutlinedInput
                                        id="outlined-adornment-telefone-register"
                                        type="text"
                                        name="telefone"
                                        inputProps={inputProps}
                                    />
                                )}
                            </InputMask>
                            {touched.telefone && errors.telefone && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.telefone}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register">Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl fullWidth error={Boolean(touched.setor && errors.setor)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-setor-register">Setor</InputLabel>
                            <Select
                                style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                id="outlined-adornment-setor-register"
                                type="text"
                                value={values.setor}
                                name="setor"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            >
                                <MenuItem value={'estoque'}>Estoque</MenuItem>
                                <MenuItem value={'tecnico'}>Técnico</MenuItem>
                                <MenuItem value={'full'}>Full</MenuItem>
                                <MenuItem value={'administração'}>Administrador</MenuItem>
                            </Select>
                            {touched.setor && errors.setor && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.setor}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-register">Senha</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-register"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    changePassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-register">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    {strength !== 0 && (
                        <FormControl fullWidth>
                            <Box sx={{ mb: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Box style={{ backgroundColor: level?.color }} sx={{ width: 85, height: 8, borderRadius: '7px' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                            {level?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
                    )}

                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}

                    {/* BOTÕES */}
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                sx={{ marginBottom: '3em' }}
                                disableElevation
                                disabled={isSubmitting || isAnyFieldEmpty(values)}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {isSubmitting || isAnyFieldEmpty(values) ? 'Campos obrigatórios faltando' : 'Cadastrar usuário'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default UserRegisterForm;
