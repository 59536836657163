import { Autocomplete, Button, Divider, Drawer, FormControl, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const ActionButtonsCreate = ({
    open,
    handleClose,
    setItens,
    campos,
    adicionarCampoItem,
    atualizarCampoItem,
    listAllItens,
    solicitacaoId
}) => {
    const api = useApi();
    const item = campos?.itens[campos?.itens?.length - 1];

    const adicionarItemNaTabela = async (novoItem) => {
        try {
            if (!novoItem.item_id) return;
            const { data } = await api.getMovSumarizadoByItemId(novoItem?.item_id);
            const quantidadeItemEstoque = data.find(
                (element) => element?.almoxarifado_id == novoItem?.almoxarifado_id && element?.solicitacao_id == novoItem?.solicitacao_id
            );
            const novoItemSemKit = {
                ...novoItem,
                solicitacao_id: solicitacaoId,
                uuid: uuidv4(),
                quantidade_estoque: quantidadeItemEstoque?.quantidade_disponivel ?? 0
            };
            setItens((prevItens) => [...prevItens, novoItemSemKit]);
        } catch (error) {
            toast.error(`Erro ao buscar dados do item: ${error.message}`);
        }
    };

    const handleChangeItem = async (newValue) => {
        atualizarCampoItem(item.uuid, 'item_id', newValue?.id);
        atualizarCampoItem(item.uuid, 'descricao', newValue?.descricao);
        atualizarCampoItem(item.uuid, 'unidade', newValue?.unidade_medida?.descricao);
        atualizarCampoItem(item.uuid, 'almoxarifado_id', newValue?.almoxarifado?.id);
        atualizarCampoItem(item.uuid, 'localizacao_id', newValue?.localizacao?.id);
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box
                sx={{
                    width: '1500px', // Ocupa 90% da largura da tela
                    maxWidth: '350px', // Limita a largura máxima a 450px
                    padding: '1em' // Adiciona espaço interno
                }}
            >
                <Box sx={{ marginTop: '1em', marginBottom: '2em' }}>
                    <Typography variant="h3">Incluir itens</Typography>
                    <Divider sx={{ margin: '1em 0' }} />
                </Box>
                <FormControl fullWidth>
                    <Autocomplete
                        options={listAllItens}
                        getOptionLabel={(item) => `${item.id} - ${item?.descricao} - ${!item.apelido ? '' : item?.apelido} `}
                        value={listAllItens.find((i) => i.id === item?.item_id) || null}
                        onChange={(e, newValue) => handleChangeItem(newValue)}
                        renderInput={(params) => <TextField {...params} label="Item" />}
                    />
                </FormControl>
                <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                    <FormControl fullWidth>
                        <TextField
                            label="Quantidade"
                            type="number"
                            value={item?.quantidade}
                            onChange={(e) => atualizarCampoItem(item?.uuid, 'quantidade', parseFloat(e.target.value))}
                        />
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                    <Tooltip title="Fechar">
                        <Button variant="contained" color="error" onClick={() => handleClose()}>
                            <CloseIcon />
                        </Button>
                    </Tooltip>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            adicionarItemNaTabela(item);
                            adicionarCampoItem();
                        }}
                    >
                        Salvar
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

export default ActionButtonsCreate;
