import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Logo from 'assets/images/logo-pdf.png';

const PdfEntradaNota = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,
            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        table: {
            display: 'table',
            width: 'auto',
            margin: '20px 0 0 0 ',
            border: 'none'
        },
        tableRow: {
            flexDirection: 'row'
        },
        tableColHeader: {
            width: '30%',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#e3e3e3',
            padding: 5
        },
        tableCol: {
            width: '30%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#d3d3d3',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 5,
            textAlign: 'start'
        },
        tableCellHeader: {
            fontSize: 10,
            fontWeight: 'bold'
        },
        tableCell: {
            fontSize: 10
        },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours());

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1);
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const formatedDay = day === 32 ? (day = '01') : day < 10 ? '0' + day : day;
        const formatedMonth = parseInt(month) < 10 ? '0' + month : month;

        return `${formatedDay}-${formatedMonth}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const reverseDate = (date) => {
        const reversedDate = date.split('-');
        return `${reversedDate[2]}-${reversedDate[1]}-${reversedDate[0]}`;
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR');
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Cabeçalho */}
                <View style={styles.header}>
                    <Text style={styles.text1}>RELATÓRIO DE ENTRADA DE NOTA</Text>
                </View>

                {/* Dados */}
                <View style={{ margin: '20px 0 0 0', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                    <Text>RESPONSÁVEL: {data?.usuario?.nome_completo}</Text>
                    <Text>DATA E HORA DA ENTRADA: {formatDate(data?.createdAt)}</Text>
                    <Text>
                        FORNECEDOR: {data?.fornecedor?.nome_fantasia} - CNPJ: {data?.fornecedor?.cnpj}
                    </Text>
                    <Text>N° NOTA: {data?.numero_documento}</Text>
                    <Text>DATA EMISSÃO: {reverseDate(data?.data_emissao)}</Text>
                </View>

                {/* Tabela */}
                <View style={styles.table}>
                    {/* Cabeçalho da tabela */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Item</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Quantidade</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Preço Unitário</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Almoxarifado</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Localização</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Total</Text>
                        </View>
                    </View>

                    {/* Linhas de dados */}
                    {data?.itens.map((item, index) => (
                        <View style={styles.tableRow} key={index} wrap={false}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.item?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.quantidade}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>R$ {item?.valor_unitario}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.almoxarifado?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.localizacao?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>R$ {item?.valor_total}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                {/* Rodapé */}
                <View fixed style={styles.fixed}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Image src={Logo} alt="Santa Casa Logotipo" style={{ width: '130px' }} />
                        <View style={{ paddingLeft: 10 }}>
                            <Text style={{ fontSize: 8 }}>BLUVALE ENGENHARIA E CLIMATIZAÇÃO</Text>
                            <Text style={{ fontSize: 8 }}>Rua Itapiranga, 337 – Sala 1 Blumenau/SC - CEP: 89.036-230</Text>
                            <Text style={{ fontSize: 8 }}>
                                TELEFONE: (47) 99223-9677 | (47) 99123-9677 - bluvale@bluvaleclimatizacao.com.br
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PdfEntradaNota;
