// assets
import {
    IconBox,
    IconClipboardList,
    IconAlignCenter,
    IconApps,
    IconBoxSeam,
    IconMapPin,
    IconBackhoe,
    IconClipboard,
    IconCoin,
    IconArrowAutofitWidth,
    IconArrowBarDown,
    IconArrowBarUp,
    IconArrowBearLeft2,
    IconScale,
    IconListSearch,
    IconCubeSend,
    IconSortDescending,
    IconSeparator
} from '@tabler/icons';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import InputIcon from '@mui/icons-material/Input';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const icons = {
    IconBox,
    Inventory2Icon,
    IconBox,
    IconClipboardList,
    IconAlignCenter,
    IconApps,
    IconBoxSeam,
    IconMapPin,
    IconBackhoe,
    IconClipboard,
    IconCoin,
    IconBox,
    IconClipboardList,
    IconAlignCenter,
    IconApps,
    IconBoxSeam,
    IconMapPin,
    IconBox,
    IconBackhoe,
    IconClipboard,
    IconCoin,
    WarehouseIcon,
    InputIcon,
    ListAltIcon,
    IconArrowAutofitWidth,
    IconArrowBarDown,
    IconArrowBarUp,
    IconArrowBearLeft2,
    IconScale,
    IconListSearch,
    IconCubeSend,
    PlaylistAddCheckIcon,
    IconSortDescending,
    DriveFileMoveIcon,
    IconSeparator,
    ReceiptLongIcon
};

let estoque;
const setor = localStorage.getItem('setor');

if (setor == 'full') {
    estoque = {
        id: 'Estoque',
        title: 'Estoque',
        type: 'group',
        children: [
            {
                id: 'menuCadastros',
                title: 'Cadastros',
                type: 'collapse',
                icon: icons.ListAltIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'almoxarifado_item',
                        title: 'Almoxarifado',
                        type: 'item',
                        url: '/main/almoxarifado',
                        icon: icons.IconBox,
                        breadcrumbs: false
                    },
                    {
                        id: 'localizacao',
                        title: 'Localização',
                        type: 'item',
                        url: '/main/localizacao',
                        icon: icons.IconMapPin,
                        breadcrumbs: false
                    },
                    {
                        id: 'fornecedor',
                        title: 'Fornecedor',
                        type: 'item',
                        url: '/main/fornecedor',
                        icon: icons.WarehouseIcon,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'entradaEstoque',
                title: 'Entrada',
                type: 'item',
                url: '/main/entrada',
                icon: icons.InputIcon,
                breadcrumbs: false
            },
            {
                id: 'movimentos',
                title: 'Movimentos',
                type: 'collapse',
                icon: icons.IconArrowAutofitWidth,
                breadcrumbs: false,
                children: [
                    {
                        id: 'baixaManual',
                        title: 'Baixa Manual',
                        type: 'item',
                        url: '/main/baixa-manual',
                        icon: icons.IconArrowBarDown,
                        breadcrumbs: false
                    },
                    {
                        id: 'entradaManual',
                        title: 'Entrada Manual',
                        type: 'item',
                        url: '/main/entrada-manual',
                        icon: icons.IconArrowBarUp,
                        breadcrumbs: false
                    },
                    {
                        id: 'transferenciaManual',
                        title: 'Transferência',
                        type: 'item',
                        url: '/main/transferencia',
                        icon: icons.IconArrowBearLeft2,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'consultas',
                title: 'Consultas',
                type: 'collapse',
                icon: icons.PlaylistAddCheckIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'saldoGeral',
                        title: 'Saldo Geral',
                        type: 'item',
                        url: '/main/saldo-geral',
                        icon: icons.IconScale,
                        breadcrumbs: false
                    },
                    {
                        id: 'kardex',
                        title: 'Kardex',
                        type: 'item',
                        url: '/main/kardex',
                        icon: icons.IconListSearch,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'solicitacoesEstoque',
                title: 'Solicitações',
                type: 'item',
                url: '/main/solicitacao',
                icon: icons.IconSortDescending,
                breadcrumbs: false
            },
            {
                id: 'separator',
                title: 'Separação',
                type: 'item',
                url: '/main/separacao-estoque',
                icon: icons.ListAltIcon,
                breadcrumbs: false
            },
            {
                id: 'liberacaoEstoque',
                title: 'Liberação',
                type: 'item',
                url: '/main/liberacao',
                icon: icons.DriveFileMoveIcon,
                breadcrumbs: false
            },
            {
                id: 'relatorios',
                title: 'Relatorios',
                type: 'collapse',
                icon: icons.ReceiptLongIcon,
                children: [
                    {
                        id: 'relatorioSolicitacao',
                        title: 'Solicitaçao',
                        type: 'item',
                        url: '/main/relatorios/solicitacao',
                        icon: icons.IconSortDescending,
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };
} else if (setor == 'administração') {
    estoque = {
        id: 'Estoque',
        title: 'Estoque',
        type: 'group',
        children: [
            {
                id: 'menuCadastros',
                title: 'Cadastros',
                type: 'collapse',
                icon: icons.ListAltIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'fornecedor',
                        title: 'Fornecedor',
                        type: 'item',
                        url: '/main/fornecedor',
                        icon: icons.WarehouseIcon,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'entradaEstoque',
                title: 'Entrada',
                type: 'item',
                url: '/main/entrada',
                icon: icons.InputIcon,
                breadcrumbs: false
            },
            {
                id: 'movimentos',
                title: 'Movimentos',
                type: 'collapse',
                icon: icons.IconArrowAutofitWidth,
                breadcrumbs: false,
                children: [
                    {
                        id: 'baixaManual',
                        title: 'Baixa Manual',
                        type: 'item',
                        url: '/main/baixa-manual',
                        icon: icons.IconArrowBarDown,
                        breadcrumbs: false
                    },
                    {
                        id: 'entradaManual',
                        title: 'Entrada Manual',
                        type: 'item',
                        url: '/main/entrada-manual',
                        icon: icons.IconArrowBarUp,
                        breadcrumbs: false
                    },
                    {
                        id: 'transferenciaManual',
                        title: 'Transferência',
                        type: 'item',
                        url: '/main/transferencia',
                        icon: icons.IconArrowBearLeft2,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'consultas',
                title: 'Consultas',
                type: 'collapse',
                icon: icons.PlaylistAddCheckIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'saldoGeral',
                        title: 'Saldo Geral',
                        type: 'item',
                        url: '/main/saldo-geral',
                        icon: icons.IconScale,
                        breadcrumbs: false
                    },
                    {
                        id: 'kardex',
                        title: 'Kardex',
                        type: 'item',
                        url: '/main/kardex',
                        icon: icons.IconListSearch,
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'liberacaoEstoque',
                title: 'Liberação',
                type: 'item',
                url: '/main/liberacao',
                icon: icons.DriveFileMoveIcon,
                breadcrumbs: false
            }
        ]
    };
} else if (setor == 'tecnico') {
    estoque = {
        id: 'Estoque',
        title: 'Estoque',
        type: 'group',
        children: [
            {
                id: 'consultas',
                title: 'Consultas',
                type: 'collapse',
                icon: icons.PlaylistAddCheckIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'saldoGeral',
                        title: 'Saldo Geral',
                        type: 'item',
                        url: '/main/saldo-geral',
                        icon: icons.IconScale,
                        breadcrumbs: false
                    },
                    {
                        id: 'kardex',
                        title: 'Kardex',
                        type: 'item',
                        url: '/main/kardex',
                        icon: icons.IconListSearch,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'solicitacoesEstoque',
                title: 'Solicitações',
                type: 'item',
                url: '/main/solicitacao',
                icon: icons.IconSortDescending,
                breadcrumbs: false
            },
            {
                id: 'separator',
                title: 'Separação',
                type: 'item',
                url: '/main/separacao-estoque',
                icon: icons.ListAltIcon,
                breadcrumbs: false
            },
            {
                id: 'liberacaoEstoque',
                title: 'Liberação',
                type: 'item',
                url: '/main/liberacao',
                icon: icons.DriveFileMoveIcon,
                breadcrumbs: false
            }
        ]
    };
} else if (setor == 'estoque') {
    estoque = {
        id: 'Estoque',
        title: 'Estoque',
        type: 'group',
        children: [
            {
                id: 'movimentos',
                title: 'Movimentos',
                type: 'collapse',
                icon: icons.IconArrowAutofitWidth,
                breadcrumbs: false,
                children: [
                    {
                        id: 'baixaManual',
                        title: 'Baixa Manual',
                        type: 'item',
                        url: '/main/baixa-manual',
                        icon: icons.IconArrowBarDown,
                        breadcrumbs: false
                    },
                    {
                        id: 'entradaManual',
                        title: 'Entrada Manual',
                        type: 'item',
                        url: '/main/entrada-manual',
                        icon: icons.IconArrowBarUp,
                        breadcrumbs: false
                    },
                    {
                        id: 'transferenciaManual',
                        title: 'Transferência',
                        type: 'item',
                        url: '/main/transferencia',
                        icon: icons.IconArrowBearLeft2,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'consultas',
                title: 'Consultas',
                type: 'collapse',
                icon: icons.PlaylistAddCheckIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'saldoGeral',
                        title: 'Saldo Geral',
                        type: 'item',
                        url: '/main/saldo-geral',
                        icon: icons.IconScale,
                        breadcrumbs: false
                    },
                    {
                        id: 'kardex',
                        title: 'Kardex',
                        type: 'item',
                        url: '/main/kardex',
                        icon: icons.IconListSearch,
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'solicitacoesEstoque',
                title: 'Solicitações',
                type: 'item',
                url: '/main/solicitacao',
                icon: icons.IconSortDescending,
                breadcrumbs: false
            },
            {
                id: 'separator',
                title: 'Separação',
                type: 'item',
                url: '/main/separacao-estoque',
                icon: icons.ListAltIcon,
                breadcrumbs: false
            },
            {
                id: 'liberacaoEstoque',
                title: 'Liberação',
                type: 'item',
                url: '/main/liberacao',
                icon: icons.DriveFileMoveIcon,
                breadcrumbs: false
            }
        ]
    };
}

export default estoque;
