// material-ui

import logo from '../assets/images/logobv.jpg';

const Logo = (props) => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <>
            <img src={logo} alt="BluVale" width={170} height={80} />
        </>
    );
};

export default Logo;
