import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import formater from 'utils/DateDDMMYYY';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { func } from 'prop-types';
import { toast } from 'react-toastify';

const LiberacaoMaterial = () => {
    const api = useApi();
    const navigate = useNavigate();
    // ----- STATES ----- \\
    const [data, setData] = useState([]);
    const DataGridColumns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        {
            field: 'data',
            headerName: 'DATA',
            flex: 1,
            valueGetter: (params) => formater(params.row.data)
        },
        {
            field: 'necessidade',
            headerName: 'NECESSIDADE',
            flex: 1,
            valueGetter: (params) => formater(params.row.necessidade)
        },
        {
            field: 'status',
            headerName: 'STATUS LIB',
            flex: 1,
            valueGetter: (params) => params.row.status
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Abrir liberação">
                        <Button onClick={() => navigate(`/main/liberacao/formulario/${params.row.id}`)}>
                            <VisibilityIcon />
                        </Button>
                    </Tooltip>
                </Box>
            )
        }
    ];

    const dataGridColumnsLiberacao = [
        {
            field: 'id',
            headerName: 'SEP ID',
            flex: 1,
            valueGetter: (params) => params.row.id
        },
        {
            field: 'data',
            headerName: 'DATA SEP',
            flex: 1,
            valueGetter: (params) => params.row.data
        },
        {
            field: 'status',
            headerName: 'STATUS SEP',
            flex: 1,
            valueGetter: (params) => params.row.status
        },
        {
            field: 'obra',
            headerName: 'OBRA',
            flex: 1
        },
        {
            field: 'projeto',
            headerName: 'PROJETO',
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip
                            title="Análisar"
                            onClick={() => {
                                navigate(`/main/liberacao/finalizancao/${params.row.id}`);
                            }}
                        >
                            <IconButton>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    // ----- FUNCTIONS ----- \\
    const getPendingLiberacao = async () => {
        try {
            const { data } = await api.getPedingv2();
            const row = data.map((item) => {
                return {
                    id: item.id,
                    data: item.data,
                    necessidade: item.necessidade,
                    status: item.status
                };
            });
            setData(data);
        } catch (error) {
            console.log('🚀 ~ getPendingLiberacao ~ error:', error);
            toast.error('Erro ao buscar liberacoes');
        }
    };

    async function getAllLiberacao() {
        try {
            const { data } = await api.getAllPendingLiberacao();
            const row = data.map((item) => {
                return {
                    id: item.id,
                    data: formater(item.data_execucao),
                    status: item.status,
                    obra: item?.solicitaco?.obra?.descricao,
                    projeto: item?.solicitaco?.projeto?.descricao
                };
            });
            setData(row);
            console.log('SDLA', data);
        } catch (error) {
            toast.error('Erro ao buscar liberações');
        }
    }

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    useEffect(() => {
        //getPendingLiberacao();
        getAllLiberacao();
    }, []);

    return (
        <MainCard title="Liberação Material">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginLeft: '1rem',
                        marginTop: '1rem'
                    }}
                >
                    <DataGrid
                        columns={dataGridColumnsLiberacao}
                        rows={data}
                        hideFooter={false}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default LiberacaoMaterial;
