// api
import { useApi } from 'Service/axios';

// react
import { useState } from 'react';

// router
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const useAuth = () => {
    const navigate = useNavigate();
    const api = useApi();

    const [authenticated, setAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loggedUser, setLoggedUser] = useState([]);

    const registerUser = async (user) => {
        try {
            const response = await api.createUser(user);
            if (response.status === 201) navigate('/main/edituser');
        } catch (error) {
            setErrorMessage(error);
            toast.error(error.response.data.error);
        }
    };

    const login = async (usuario, password) => {
        try {
            const response = await api.signin(usuario, password);
            const data = response.data;
            localStorage.setItem('userIdTriunfo', data?.user?.id);
            setLoggedUser(data);
            await authUser(data);
            window.location.reload();
            navigate('/main');
        } catch (error) {
            setErrorMessage(error);
            toast.error(error.message);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('setor');
        localStorage.removeItem('nome');
        localStorage.removeItem('id');
        navigate('/');
    };

    //* Salva o dados no localStorage
    const authUser = async (data) => {
        setAuthenticated(true);
        localStorage.setItem('token', data.token);
        localStorage.setItem('setor', data?.user?.setor);
        localStorage.setItem('id', data?.user?.id);
        localStorage.setItem('nome', data?.user?.nome_completo);
        navigate('/main/dashboard');
    };

    return { authenticated, errorMessage, loggedUser, login, registerUser, logout };
};

export default useAuth;
