import { Autocomplete, Button, Drawer, FormControl, MenuItem, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const ActionButtons = ({ setItens, row, removerCampoItem, listAllItens, listAllAlmoxarifado, listAllLocalizacao }) => {
    /////////////////////////////// STATES /////////////////////////
    const [open, setOpen] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleItemSelect = async (selectedItemId) => {
        const selectedItem = listAllItens.find((item) => item.id === selectedItemId.id);
        setItemSelecionado({
            ...itemSelecionado,
            item_id: selectedItem.id,
            descricao: selectedItem.descricao,
            unidade: selectedItem.unidade_medida.descricao,
            almoxarifado_id: selectedItem.almoxarifado.id,
            localizacao_id: selectedItem.localizacao.id,
            observacao: selectedItem.observacao
        });
    };

    const handleValorUnitario = (e, itemSelecionado) => {
        const novoValorUnitario = parseFloat(e.target.value);
        const novoValorTotal = parseFloat(itemSelecionado.quantidade * novoValorUnitario);

        setItemSelecionado({
            ...itemSelecionado,
            valor_unitario: novoValorUnitario,
            valor_total: novoValorTotal
        });
    };
    const atualizarCampoItemSelecionado = (item, campo, valor) => {
        setItemSelecionado({
            ...item,
            [campo]: campo === 'item_id' || campo === 'quantidade' || campo === 'valor_unitario' ? parseInt(valor) : valor
        });
    };

    const handleEditItem = (itemSelecionado) => {
        setItens((prevItens) => {
            const indiceItem = prevItens.findIndex((item) => item.uuid === itemSelecionado.uuid);
            if (indiceItem !== -1) {
                const novosItens = [...prevItens];
                novosItens[indiceItem] = itemSelecionado;
                return novosItens;
            }
            return prevItens;
        });
        setOpen(false);
    };
    useEffect(() => {
        setItemSelecionado({ ...row });
    }, []);

    return (
        <Box>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    {itemSelecionado !== null && (
                        <Box>
                            <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={listAllItens}
                                        getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                                        value={listAllItens.find((el) => el.id === itemSelecionado?.item_id) || null}
                                        onChange={(e, newValue) => handleItemSelect(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Item" />}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="UN"
                                        value={itemSelecionado ? itemSelecionado?.unidade : ''}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        value={parseFloat(itemSelecionado.quantidade)}
                                        onChange={(e) =>
                                            atualizarCampoItemSelecionado(itemSelecionado, 'quantidade', parseFloat(e.target.value))
                                        }
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Valor Unitário"
                                        type="number"
                                        value={itemSelecionado ? itemSelecionado.valor_unitario : 0}
                                        onChange={(e) => handleValorUnitario(e, itemSelecionado)}
                                    />
                                </FormControl>
                            </Box>
                            <FormControl fullWidth>
                                <TextField label="Valor Total" type="number" value={itemSelecionado.valor_total} disabled />
                            </FormControl>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Almoxarifado"
                                        select
                                        value={itemSelecionado.almoxarifado_id}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'almoxarifado_id', e.target.value)}
                                    >
                                        {listAllAlmoxarifado.map((almoxarifado) => (
                                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                {almoxarifado.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Localização"
                                        select
                                        value={itemSelecionado.localizacao_id}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'localizacao_id', e.target.value)}
                                    >
                                        {listAllLocalizacao.map((localizacao) => (
                                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                                {localizacao.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Observação"
                                        value={itemSelecionado.observacao}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                                <Tooltip title="Fechar">
                                    <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </Button>
                                </Tooltip>
                                <Button variant="contained" onClick={(e) => handleEditItem(itemSelecionado)}>
                                    Salvar alterações
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Drawer>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Tooltip title="Editar Item">
                    <Button sx={{ all: 'unset', cursor: 'pointer' }} onClick={handleOpen}>
                        <EditIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Remover Item">
                    <Button sx={{ all: 'unset', cursor: 'pointer' }} onClick={() => removerCampoItem(row.uuid)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ActionButtons;
