import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Checkbox, OutlinedInput, Select, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useApi } from 'Service/axios';
import { FormBox } from './style';
import { useParams, useNavigate } from 'react-router-dom';

const EditarItem = ({ item, ...others }) => {
    // == INSTÂNCIAS ===
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    // == STATES ===
    const [categorias, setCategorias] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [almoxarfados, setAlmoxarifados] = useState([]);
    const [localizacoes, setLocalizacoes] = useState([]);
    const [editFiltredLocations, setEditFiltredLocations] = useState([]);
    // == FUNÇÕES ===
    const handleChangeLocations = (location) => {
        console.log(location);
        const filtredData = localizacoes.filter((i) => i.almoxarifado.id === location);
        setEditFiltredLocations(filtredData);
    };

    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['descricao'];

        for (const field of fieldsToCheck) {
            if (values[field].trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleUpdate = async (values) => {
        if (values.ativo === 1) {
            values.ativo = true;
        } else {
            values.ativo = false;
        }
        // // monta um objeto apenas com valores !== de ""
        // const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
        //     if (value !== '') {
        //         acc[key] = value;
        //     }
        //     return acc;
        // }, {});

        try {
            await api.updateItem(id, values);
            navigate('/main/item');
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const get = async () => {
            try {
                const [categoria, unidade, almoxarifado, localizacao] = await Promise.all([
                    api.getAllCategory(),
                    api.getAllUnit(),
                    api.getAllAlmoxarifado(),
                    api.getAllLocalizacao()
                ]);

                setCategorias(categoria.data.categorias);
                setUnidades(unidade.data.unidades);
                setAlmoxarifados(almoxarifado.data.almoxarifados);
                setLocalizacoes(localizacao.data.localizacao);

                // Filtra as localizações com base nos dados iniciais do item
                setEditFiltredLocations(localizacao.data.localizacao.filter((i) => i.id_almoxarifado === item?.almoxarifado_id));
            } catch (error) {
                console.error(error);
            }
        };

        get();
    }, [item]);

    return (
        <>
            {item !== null && localizacoes.length !== 0 && (
                <Formik
                    initialValues={{
                        descricao: item.descricao,
                        apelido: item.apelido,
                        unidade_medida_id: item.unidade_medida_id,
                        categoria_id: item.categoria_id,
                        quantidade_minima: item.quantidade_minima,
                        lote_economico: item.lote_economico,
                        almoxarifado_id: item.almoxarifado_id,
                        localizacao_id: item.localizacao_id,
                        ativo: item.ativo === true ? 1 : 2,
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        descricao: Yup.string().required('Descrição é obrigatório')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleUpdate(values);
                        } catch (err) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            {...others}
                            style={{ display: 'flex', flexDirection: 'column', marginTop: '2em', width: '80%' }}
                        >
                            <Typography sx={{ textAlign: 'center', marginBottom: '2em' }} variant="h4">
                                ITEM: {item.descricao}
                            </Typography>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.descricao && errors.descricao)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-descricao-register"
                                        type="text"
                                        value={values.descricao}
                                        name="descricao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.descricao && errors.descricao && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.descricao}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.apelido && errors.apelido)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-apelido-register">Apelido</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-apelido-register"
                                        type="text"
                                        value={values.apelido}
                                        name="apelido"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.apelido && errors.apelido && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.apelido}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.unidade_medida_id && errors.unidade_medida_id)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-unidade_medida_id-register">Unidade de Medida</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-unidade_medida_id-register"
                                        type="text"
                                        value={values.unidade_medida_id}
                                        name="unidade_medida_id"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        {unidades.length > 0 &&
                                            unidades.map((item) => (
                                                <MenuItem value={item.id} key={item.id}>
                                                    {item.descricao}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.unidade_medida_id && errors.unidade_medida_id && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.unidade_medida_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.categoria_id && errors.categoria_id)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-categoria_id-register">Categoria</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-categoria_id-register"
                                        type="text"
                                        value={values.categoria_id}
                                        name="categoria_id"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        {categorias.length > 0 &&
                                            categorias.map((item) => (
                                                <MenuItem value={item.id} key={item.id}>
                                                    {item.descricao}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.categoria_id && errors.categoria_id && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.categoria_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.quantidade_minima && errors.quantidade_minima)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-quantidade_minima-register">Quantidade Mínima</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-quantidade_minima-register"
                                        type="number"
                                        value={values.quantidade_minima}
                                        name="quantidade_minima"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.quantidade_minima && errors.quantidade_minima && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.quantidade_minima}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.lote_economico && errors.lote_economico)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-lote_economico-register">Lote Econômico</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-lote_economico-register"
                                        type="number"
                                        value={values.lote_economico}
                                        name="lote_economico"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.lote_economico && errors.lote_economico && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.lote_economico}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.almoxarifado_id && errors.almoxarifado_id)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-almoxarifado_id-register">Almoxarifado</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-almoxarifado_id-register"
                                        type="text"
                                        value={values.almoxarifado_id}
                                        name="almoxarifado_id"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e), handleChangeLocations(e.target.value);
                                        }}
                                        inputProps={{}}
                                    >
                                        {almoxarfados.length > 0 &&
                                            almoxarfados.map((item) => (
                                                <MenuItem value={item.id} key={item.id}>
                                                    {item.descricao}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.almoxarifado_id && errors.almoxarifado_id && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.almoxarifado_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.localizacao_id && errors.localizacao_id)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-localizacao_id-register">Localizacao</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-localizacao_id-register"
                                        type="text"
                                        value={values.localizacao_id}
                                        name="localizacao_id"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        {editFiltredLocations.length > 0 &&
                                            editFiltredLocations.map((item) => (
                                                <MenuItem value={item.id} key={item.id}>
                                                    {item.descricao}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.localizacao_id && errors.localizacao_id && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.localizacao_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            {/* <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.localizacao_id && errors.localizacao_id)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-ativo-register">Ativo</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-ativo-register"
                                        type="text"
                                        value={values.ativo}
                                        name="ativo"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        <MenuItem value={1}>Sim</MenuItem>
                                        <MenuItem value={2}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormBox> */}

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            {/* BOTÕES */}
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <Button color="error" variant="contained" onClick={() => navigate('/main/item')}>
                                    voltar
                                </Button>

                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting || isAnyFieldEmpty(values)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {isSubmitting || isAnyFieldEmpty(values) ? 'Dados do item estão faltando' : 'Atualizar Item'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditarItem;
