import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Logo from 'assets/images/logo-pdf.png';

const PdfBaixaManual = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,
            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        table: {
            display: 'table',
            width: 'auto',
            margin: '20px 0 0 0 ',
            border: 'none'
        },
        tableRow: {
            flexDirection: 'row'
        },
        tableColHeader: {
            width: '30%',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#e3e3e3',
            padding: 5
        },
        tableCol: {
            width: '30%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#d3d3d3',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 5,
            textAlign: 'start'
        },
        tableCellHeader: {
            fontSize: 10,
            fontWeight: 'bold'
        },
        tableCell: {
            fontSize: 10
        },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const formatDate = (dateString) => {
        // Converte a string para um objeto Date
        const date = new Date(dateString);

        // Subtrai 3 horas
        // date.setHours(date.getHours() - 3);
        date.setHours(date.getHours());

        // Formata a data no formato dd-mm-yyyy hh:mm:ss
        const day = String(date.getDate());
        const month = String(date.getMonth() + 1); // Os meses começam em 0
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const formatedDay = day === 32 ? (day = '01') : day < 10 ? '0' + day : day;
        const formatedMonth = parseInt(month) < 10 ? '0' + month : month;

        return `${formatedDay}-${formatedMonth}-${year} ${hours}:${minutes}:${seconds}`;
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Cabeçalho */}
                <View style={styles.header}>
                    <Text style={styles.text1}>RELATÓRIO DE BAIXA MANUAL</Text>
                </View>

                {/* Dados */}
                <View style={{ margin: '20px 0 0 0', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                    <Text>RESPONSÁVEL: {data?.movimento?.responsavel?.nome_completo}</Text>
                    <Text>DATA E HORA DA SAIDA: {formatDate(data?.createdAt)}</Text>
                </View>

                {/* Tabela */}
                <View style={styles.table}>
                    {/* Cabeçalho da tabela */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Item</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Quantidade</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Almoxarifado</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Localização</Text>
                        </View>
                    </View>

                    {/* Linhas de dados */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.item?.descricao}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.quantidade}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.almoxarifado?.descricao}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.localizacao?.descricao}</Text>
                        </View>
                    </View>
                </View>

                {/* Rodapé */}
                <View fixed style={styles.fixed}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Image src={Logo} alt="Santa Casa Logotipo" style={{ width: '130px' }} />
                        <View style={{ paddingLeft: 10 }}>
                            <Text style={{ fontSize: 8 }}>BLUVALE ENGENHARIA E CLIMATIZAÇÃO</Text>
                            <Text style={{ fontSize: 8 }}>Rua Itapiranga, 337 – Sala 1 Blumenau/SC - CEP: 89.036-230</Text>
                            <Text style={{ fontSize: 8 }}>
                                TELEFONE: (47) 99223-9677 | (47) 99123-9677 - bluvale@bluvaleclimatizacao.com.br
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PdfBaixaManual;
