import { useParams, Link, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField, Typography, Modal } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import { toast } from 'react-toastify';
import EntradaTabela from 'components/Entrada/Tabela';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsTabela from 'components/Entrada/ActionButtonTabela';
import { PDFViewer } from '@react-pdf/renderer';
import PdfEntradaNota from 'components/PDF/Estoque/PdfEntradaNota';
const stylePdf = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '90vh'
};

const EntradaEstoque = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [allEntrada, setAllEntrada] = useState([]);
    const [selectedEntrada, setSelectedEntrada] = useState(null);
    const [entrada, setEntrada] = useState(null);
    const [dataPdf, setDataPdf] = useState(null);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'numero_documento', headerName: 'NÚMERO DOCUMENTO', flex: 2 },
        { field: 'fornecedor', headerName: 'FORNECEDOR', flex: 2, valueGetter: (params) => params.row.fornecedor.nome_fantasia },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <ActionButtonsTabela row={params.row} setOpenModalPdf={setOpenModalPdf} getEntradaById={getDataEntradaById} />
            )
        }
    ];
    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClickOpenEdit = (e, item) => {
        e.preventDefault();
        setEntrada(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNavigate = () => {
        navigate('/main/estoque/entrada/cadastro');
    };

    const getEntradas = async () => {
        try {
            const response = await api.getAllEntradasEstoques();
            setAllEntrada(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    async function getDataEntradaById(id) {
        try {
            const { data } = await api.getEntradaEstoqueById(id);
            setDataPdf(data);
            setOpenModalPdf(true);
        } catch (error) {
            toast.error(`Erro ao buscar entrada: ${error.message}`);
        }
    }

    useEffect(() => {
        getEntradas();
    }, []);

    return (
        <MainCard>
            <Modal
                open={openModalPdf}
                onClose={() => {
                    setOpenModalPdf(false);
                }}
            >
                <Box sx={stylePdf}>
                    <PDFViewer width={'100%'} height={'100%'}>
                        <PdfEntradaNota data={dataPdf} />
                    </PDFViewer>
                </Box>
            </Modal>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2em'
                }}
            >
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>Entrada</Typography>
                <Button variant="contained" size="medium" onClick={handleNavigate} sx={{ height: '40px' }}>
                    Adicionar
                </Button>
            </Box>
            <Grid container spacing={gridSpacing}>
                <TableBox>
                    <DataGrid
                        rows={allEntrada}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default EntradaEstoque;
