import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { TableBox, TableDatagrid } from './style';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsTabela from 'components/Estoque/Solicitacao/ActionButtonTabela';
import formater from 'utils/DateDDMMYYY';

const SolicitacaoEstoque = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [allSolicitacao, setAllSolicitacao] = useState([]);

    const columns = [
        { field: 'id', headerName: 'ID', sortable: false, flex: 0.2 },
        {
            field: 'data',
            headerName: 'DATA',
            flex: 0.4,
            valueGetter: (params) => formater(params?.row?.data)
        },
        {
            field: 'necessidade',
            headerName: 'NECESSIDADE',
            flex: 0.4,
            valueGetter: (params) => formater(params?.row?.necessidade),
            sortable: false
        },
        {
            field: 'obra',
            headerName: 'OBRA',
            flex: 0.5,
            valueGetter: (params) => params?.row?.obra?.descricao
        },
        {
            field: 'projeto',
            headerName: 'PROJETO',
            flex: 1,
            valueGetter: (params) => params?.row?.projeto?.descricao,
            sortable: false
        },
        { field: 'status', headerName: 'STATUS LIB', flex: 0.4, sortable: false },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => <ActionButtonsTabela row={params?.row} getSolicitacoes={getSolicitacoes} />
        }
    ];

    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleNavigate = () => {
        navigate('/main/nova-solicitacao');
    };

    const getSolicitacoes = async () => {
        try {
            const response = await api.getAllSolicitacao();
            setAllSolicitacao(response.data.solicitacoes);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getSolicitacoes();
    }, []);

    return (
        <MainCard>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2em'
                }}
            >
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>Solicitações</Typography>
                <Button variant="contained" size="medium" onClick={handleNavigate} sx={{ height: '40px' }}>
                    Adicionar
                </Button>
            </Box>
            <Grid container spacing={gridSpacing}>
                <TableBox>
                    <TableDatagrid
                        rows={allSolicitacao}
                        columns={columns}
                        hideFooter={true}
                        getRowHeight={() => 'auto'}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default SolicitacaoEstoque;
