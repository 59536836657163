import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Logo from 'assets/images/logo-pdf.png';

const PdfSolicitacao = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 35,
            paddingBottom: 110,
            paddingHorizontal: 35,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,
            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        table: {
            display: 'table',
            width: 'auto',
            margin: '20px 0 0 0 ',
            border: 'none'
        },
        tableRow: {
            flexDirection: 'row'
        },
        tableColHeader: {
            width: '34%',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#e3e3e3',
            padding: 5
        },
        tableColHeaderID: {
            width: '12%',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#e3e3e3',
            padding: 5
        },
        tableColHeaderQTD: {
            width: '23%',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#e3e3e3',
            padding: 5
        },
        tableColHeaderSALDO: {
            width: '18%',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#e3e3e3',
            padding: 5
        },
        tableCol: {
            width: '34%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#d3d3d3',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 5,
            textAlign: 'start'
        },
        tableColID: {
            width: '12%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#d3d3d3',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 5,
            textAlign: 'start'
        },
        tableColSALDO: {
            width: '18%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#d3d3d3',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 5,
            textAlign: 'start'
        },
        tableColQTD: {
            width: '23%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#d3d3d3',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 5,
            textAlign: 'start'
        },
        tableCellHeader: {
            fontSize: 10,
            fontWeight: 'bold'
        },
        tableCell: {
            fontSize: 10
        },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const formatDate = (dateString) => {
        const date = dateString.split('-');
        return `${date[2]}-${date[1]}-${date[0]}`;
    };

    const buildItemStatus = (item) => {
        if (item.quantidade_restante == 0) {
            return 'Concluído';
        } else {
            return 'Parcial';
        }
    };

    const buildItemQtdRestante = (item) => {
        if (item.quantidade_restante == 0) {
            return item.quantidade;
        } else if (item.quantidade_restante == null) {
            return 0;
        } else {
            return item.quantidade - item.quantidade_restante;
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR');
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Cabeçalho */}
                <View style={styles.header}>
                    <Text style={styles.text1}>RELATÓRIO DE SOLICITAÇÃO DE OBRAS</Text>
                    <Text style={styles.text1}>
                        EMITIDO EM: {date} | {time}
                    </Text>
                </View>
                {/* Dados */}
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '7px',
                        padding: '10px',
                        marginTop: '8px',
                        backgroundColor: '#e3e3e3'
                    }}
                >
                    <Text>DATA INICIAL: {formatDate(data.dadosFiltro.dataInicial)}</Text>
                    <Text>DATA FINAL: {formatDate(data.dadosFiltro.dataFinal)}</Text>
                </View>
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '7px',
                        padding: '10px',
                        backgroundColor: '#e3e3e3'
                    }}
                >
                    <Text>OBRA: {data?.solicitacoes[0]?.obra?.descricao}</Text>
                    <Text>PROJETO: {data?.dadosFiltro.projetoId.lenght > 0 ? data?.solicitacoes[0]?.projeto?.descricao : 'TODOS'}</Text>
                </View>
                {/* Mapeamento de Solicitacoes */}
                {data.solicitacoes.map((element, index) => (
                    <>
                        <View key={index}>
                            {/* Tabela */}
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>ID Solicitação</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Usuário Solicitante</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Data Inclusão</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Data Necessidade</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Projeto</Text>
                                    </View>
                                </View>

                                <View style={styles.tableRow} wrap={false}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{element?.id}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{element?.solicitante?.nome_completo}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{formatDate(element?.data)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{formatDate(element?.necessidade)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{element?.projeto?.descricao}</Text>
                                    </View>
                                </View>
                            </View>

                            <View
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    marginTop: '12px'
                                }}
                            >
                                <Text>OBSERVAÇÃO: {element.observacao}</Text>
                            </View>

                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>ID Separação</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Data Separação</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Responsável</Text>
                                    </View>
                                </View>

                                {element.separacao.map((separacao, index) => (
                                    <View key={index} style={styles.tableRow} wrap={false}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{separacao?.id}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{separacao?.data_execucao}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{separacao?.usuario?.nome_completo}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>

                            {/* Tabela */}
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColHeaderID}>
                                        <Text style={styles.tableCellHeader}>ID</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Descrição</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Unidade</Text>
                                    </View>
                                    <View style={styles.tableColHeaderQTD}>
                                        <Text style={styles.tableCellHeader}>Qt Solicitada</Text>
                                    </View>
                                    <View style={styles.tableColHeaderQTD}>
                                        <Text style={styles.tableCellHeader}>Qt Retirada</Text>
                                    </View>
                                    <View style={styles.tableColHeaderSALDO}>
                                        <Text style={styles.tableCellHeader}>Saldo</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>Status</Text>
                                    </View>
                                </View>

                                {element.item_id.map((item, indexItem) => (
                                    <View style={styles.tableRow} key={indexItem} wrap={false}>
                                        <View style={styles.tableColID}>
                                            <Text style={styles.tableCell}>{item?.item?.id}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{item?.item?.descricao}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{item?.item?.unidade_medida?.descricao}</Text>
                                        </View>
                                        <View style={styles.tableColQTD}>
                                            <Text style={styles.tableCell}>{item?.quantidade}</Text>
                                        </View>
                                        <View style={styles.tableColQTD}>
                                            <Text style={styles.tableCell}>{buildItemQtdRestante(item)}</Text>
                                        </View>
                                        <View style={styles.tableColSALDO}>
                                            <Text style={styles.tableCell}>{item?.saldo}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{buildItemStatus(item)}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                        <View style={{ borderTop: '1px solid #000', width: '100%', padding: '1px', marginTop: '20px' }} />
                    </>
                ))}
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '7px',
                        padding: '10px',
                        marginTop: '8px',
                        backgroundColor: '#e3e3e3'
                    }}
                >
                    <Text>RESUMO DE MATERIAL</Text>
                </View>
                <View>
                    {/* Tabela */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderID}>
                                <Text style={styles.tableCellHeader}>ID</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Descrição</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Unidade</Text>
                            </View>
                            <View style={styles.tableColHeaderQTD}>
                                <Text style={styles.tableCellHeader}>Qt Solicitada</Text>
                            </View>
                            <View style={styles.tableColHeaderQTD}>
                                <Text style={styles.tableCellHeader}>Qt Retirada</Text>
                            </View>
                            <View style={styles.tableColHeaderSALDO}>
                                <Text style={styles.tableCellHeader}>Saldo</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Status</Text>
                            </View>
                        </View>
                        {data.resumo.map((element, index) => (
                            <View key={index} style={styles.tableRow} wrap={false}>
                                <View style={styles.tableColID}>
                                    <Text style={styles.tableCell}>{element?.item_id}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{element?.descricao}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{element?.unidade}</Text>
                                </View>
                                <View style={styles.tableColQTD}>
                                    <Text style={styles.tableCell}>{element?.quantidade_solicitada}</Text>
                                </View>
                                <View style={styles.tableColQTD}>
                                    <Text style={styles.tableCell}>{element?.quantidade_retirada}</Text>
                                </View>
                                <View style={styles.tableColSALDO}>
                                    <Text style={styles.tableCell}>{element?.saldo}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {element?.quantidade_solicitada == element?.quantidade_retirada ? 'Concluído' : 'Parcial'}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>

                {/* Rodapé */}
                <View fixed style={styles.fixed}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Image src={Logo} alt="Santa Casa Logotipo" style={{ width: '130px' }} />
                        <View style={{ paddingLeft: 10 }}>
                            <Text style={{ fontSize: 8 }}>BLUVALE ENGENHARIA E CLIMATIZAÇÃO</Text>
                            <Text style={{ fontSize: 8 }}>Rua Itapiranga, 337 – Sala 1 Blumenau/SC - CEP: 89.036-230</Text>
                            <Text style={{ fontSize: 8 }}>
                                TELEFONE: (47) 99223-9677 | (47) 99123-9677 - bluvale@bluvaleclimatizacao.com.br
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PdfSolicitacao;
