import React from 'react';
import { useApi } from 'Service/axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, IconButton } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ActionButtonsTabelaItens = ({ row, getAllItems }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleDeleteItem = async (itemId) => {
        try {
            await api.deleteItem(itemId);
            setOpenDialog(false);
            getAllItems();
            toast.success('Item deletado com sucesso');
        } catch (error) {
            setOpenDialog(false);
            toast.error(`Erro: Item possui vinculo com movimentação de estoque`);
        }
    };

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>Tem certeza que deseja excluir esse item?</DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpenDialog(false)}>
                        não
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteItem(row.id)}>
                        sim
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Editar Item">
                <Link to={`/main/item/edit/${row.id}`}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Link>
            </Tooltip>
            <Tooltip title="Remover Item">
                <Button onClick={() => setOpenDialog(true)}>
                    <DeleteIcon />
                </Button>
            </Tooltip>
        </Box>
    );
};

export default ActionButtonsTabelaItens;
