// assets
import {
    IconBox,
    IconClipboardList,
    IconAlignCenter,
    IconApps,
    IconBoxSeam,
    IconMapPin,
    IconBackhoe,
    IconClipboard,
    IconCoin
} from '@tabler/icons';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import InputIcon from '@mui/icons-material/Input';
import ListAltIcon from '@mui/icons-material/ListAlt';

const icons = {
    IconBox,
    IconClipboardList,
    IconAlignCenter,
    IconApps,
    IconBoxSeam,
    IconMapPin,
    IconBox,
    IconBackhoe,
    IconClipboard,
    IconCoin,
    WarehouseIcon,
    InputIcon,
    ListAltIcon
};

let cadastros;
const setor = localStorage.getItem('setor');

if (setor == 'administração') {
    cadastros = {
        id: 'cadastros',
        title: 'Cadastros',
        type: 'group',
        children: [
            {
                id: 'CentroCusto',
                title: 'Centro de Custo',
                type: 'item',
                url: '/main/centroCusto',
                icon: icons.IconCoin,
                breadcrumbs: false
            }
        ]
    };
} else if (setor == 'full') {
    cadastros = {
        id: 'cadastros',
        title: 'Cadastros',
        type: 'group',
        children: [
            {
                id: 'catergoria',
                title: 'Categoria de Item',
                type: 'item',
                url: '/main/item/categoria',
                icon: icons.IconApps,
                breadcrumbs: false
            },
            {
                id: 'unidade',
                title: 'Unidades de Medida',
                type: 'item',
                url: '/main/unidade',
                icon: icons.IconAlignCenter,
                breadcrumbs: false
            },
            {
                id: 'itens',
                title: 'Itens',
                type: 'item',
                url: '/main/item',
                icon: icons.IconClipboardList,
                breadcrumbs: false
            },
            {
                id: 'obrasMenu',
                title: 'Obras',
                type: 'item',
                url: '/main/obras',
                icon: icons.IconBackhoe,
                breadcrumbs: false
            },
            {
                id: 'projetosMenu',
                title: 'Projetos',
                type: 'item',
                url: '/main/projetos',
                icon: icons.IconClipboard,
                breadcrumbs: false
            },
            {
                id: 'CentroCusto',
                title: 'Centro de Custo',
                type: 'item',
                url: '/main/centroCusto',
                icon: icons.IconCoin,
                breadcrumbs: false
            }
        ]
    };
} else if (setor == 'estoque') {
    cadastros = { id: 'null', type: 'group' };
} else if (setor == 'tecnico') {
    cadastros = {
        id: 'cadastros',
        title: 'Cadastros',
        type: 'group',
        children: [
            {
                id: 'obrasMenu',
                title: 'Obras',
                type: 'item',
                url: '/main/obras',
                icon: icons.IconBackhoe,
                breadcrumbs: false
            },
            {
                id: 'projetosMenu',
                title: 'Projetos',
                type: 'item',
                url: '/main/projetos',
                icon: icons.IconClipboard,
                breadcrumbs: false
            }
        ]
    };
}

export default cadastros;
