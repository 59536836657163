import React from 'react';
import { useApi } from 'Service/axios';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const ActionButtonEdit = ({ setCampos, itens, setItens, row, atualizarCampoItem, removerCampoItem, listAllItens }) => {
    const [open, setOpen] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleItemSelect = async (selectedItemId) => {
        const selectedItem = listAllItens.find((item) => item.id === selectedItemId.id);
        setItemSelecionado({
            ...itemSelecionado,
            item_id: selectedItem.id,
            descricao: selectedItem.descricao,
            unidade: selectedItem.unidade_medida.descricao,
            observacao: selectedItem.observacao
        });
    };

    const atualizarCampoItemSelecionado = (item, campo, valor) => {
        setItemSelecionado({
            ...item,
            [campo]: campo === 'item_id' || campo === 'quantidade' ? parseInt(valor) : valor
        });
    };

    const handleEditItem = (itemSelecionado) => {
        setItens((prevItens) => {
            const indiceItem = prevItens.findIndex((item) => item.uuid === itemSelecionado.uuid);
            if (indiceItem !== -1) {
                const novosItens = [...prevItens];
                novosItens[indiceItem] = itemSelecionado;
                return novosItens;
            }
            return prevItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            const indiceItem = novosCampos.itens.findIndex((item) => item.uuid === itemSelecionado.uuid);

            if (indiceItem !== -1) {
                const item = novosCampos.itens[indiceItem];
                item.action = 'update';
            }
            return novosCampos;
        });
        setOpen(false);
    };

    useEffect(() => {
        setItemSelecionado({ ...row });
    }, []);

    return (
        <Box>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <Box
                    sx={{
                        width: '1500px', // Ocupa 90% da largura da tela
                        maxWidth: '350px', // Limita a largura máxima a 450px
                        padding: '1em'
                    }}
                >
                    <h3>Editar item</h3>
                    <Divider sx={{ margin: '1em 0' }} />
                    {itemSelecionado !== null && (
                        <Box>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={listAllItens}
                                    getOptionLabel={(item) => `${item.id} - ${item?.descricao} - ${!item.apelido ? '' : item.apelido} `}
                                    value={listAllItens.find((el) => el?.id === itemSelecionado?.item_id) || null}
                                    onChange={(e, newValue) => handleItemSelect(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Item" />}
                                />
                            </FormControl>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        value={parseFloat(itemSelecionado?.quantidade)}
                                        onChange={(e) =>
                                            atualizarCampoItemSelecionado(itemSelecionado, 'quantidade', parseFloat(e.target.value))
                                        }
                                    />
                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', marginTop: '2em', justifyContent: 'flex-end', gap: '1em' }}>
                                <Tooltip title="Fechar e cancelar">
                                    <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                                        Cancelar Edição
                                    </Button>
                                </Tooltip>
                                <Button variant="contained" onClick={(e) => handleEditItem(itemSelecionado)}>
                                    <SaveIcon />
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Drawer>
            <Box>
                <Tooltip title="Editar Item">
                    <Button onClick={handleOpen}>
                        <EditIcon />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ActionButtonEdit;
