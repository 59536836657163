import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NotAllowed from 'views/NotAllowed';
import { getAccessLevel } from 'utils/getAccessLevel';
import Register from 'views/pages/authentication/authentication3/Register3';
import EditUser from 'views/users/EditUser';
import Estoque from 'views/Lobby';
import CategoriaItem from 'views/CategoriaItem';
import EditarCategoria from 'views/CategoriaItem/edit/EditarCategoria';
import Itens from 'views/Itens';
import EditItem from 'views/Itens/edit/EditItem';
import UnidadeMedida from 'views/UnidadeMedida';
import EditarUnidade from 'views/UnidadeMedida/edit/EditarUnidade';
import Almoxarifado from 'views/Almoxarifado';
import EditarAlmoxarifado from 'views/Almoxarifado/edit/EditarAlmoxarifado';
import Localizacao from 'views/Localizacao';
import EditarLocalizacao from 'views/Localizacao/edit/EditarLocalizacao';
import Obras from 'views/Obras';
import EditarObra from 'views/Obras/Edit/EditarObra';
import CentroCustos from 'views/CentroCusto';
import Projetos from 'views/Projetos';
import EditarProjeto from 'views/Projetos/Edit/EditarProjeto';
import EditarCentroCusto from 'views/CentroCusto/edit/EditarCentroCusto';
import Fornecedor from 'views/Fornecedor';
import EntradaEstoque from 'views/Entrada';
import NovaEntrada from 'views/Entrada/NovaEntrada';
import EditarEntrada from 'views/Entrada/EditarEntrada';
import EntradaManual from 'views/Estoque/Movimentos/Entrada';
import BaixaManual from 'views/Estoque/Movimentos/Baixa';
import TransferenciaManual from 'views/Estoque/Movimentos/Transferencia';
import SaldoGeral from 'views/Estoque/SaldoGeral';
import Kardex from 'views/Estoque/Kardex';
import SolicitacaoEstoque from 'views/Estoque/Solicitacoes';
import NovaSolicitacao from 'views/Estoque/Solicitacoes/NovaSolicitacao';
import EditarSolicitacao from 'views/Estoque/Solicitacoes/EditarSolicitacao';
import SeparacaoDeMaterial from 'views/Estoque/Separacao';
import LiberacaoMaterial from 'views/Estoque/Liberacao';
import FormularioLiberacao from 'views/Estoque/Liberacao/PrimeiroNivel';
import Separacao from 'views/Estoque/SeparacaoMainPage';
import PrimeiroNivel from 'views/Estoque/SeparacaoMainPage/PrimeiroNivel';
import { FinalSeparation } from 'views/Estoque/Liberacao/FinalSeparation';
import RelatorioSolicitacao from 'views/Estoque/Relatorios/RelatorioSolicitacao';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/main',
    element: <MainLayout />,
    children: [
        {
            path: '/main/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/main/register',
            element: <Register />
        },
        {
            path: '/main/edituser',
            element: <EditUser />
        },
        {
            path: '/main/estoque',
            element: <Estoque />
        },
        {
            path: '/main/item/categoria',
            element: <CategoriaItem />
        },
        {
            path: '/main/item/categoria/edit/:id',
            element: <EditarCategoria />
        },
        {
            path: '/main/item',
            element: <Itens />
        },
        {
            path: '/main/item/edit/:id',
            element: <EditItem />
        },
        {
            path: '/main/unidade',
            element: <UnidadeMedida />
        },
        {
            path: '/main/unidade/edit/:id',
            element: <EditarUnidade />
        },
        {
            path: '/main/almoxarifado',
            element: <Almoxarifado />
        },
        {
            path: '/main/edit/almoxarifado/:id',
            element: <EditarAlmoxarifado />
        },
        {
            path: '/main/localizacao',
            element: <Localizacao />
        },
        {
            path: '/main/edit/localizacao/:id',
            element: <EditarLocalizacao />
        },
        {
            path: '/main/obras',
            element: <Obras />
        },
        {
            path: '/main/edit/obra/:id',
            element: <EditarObra />
        },
        {
            path: '/main/centrocusto',
            element: <CentroCustos />
        },
        {
            path: '/main/projetos',
            element: <Projetos />
        },
        {
            path: '/main/projetos/edit/:id',
            element: <EditarProjeto />
        },
        {
            path: '/main/centrocusto/edit/:id',
            element: <EditarCentroCusto />
        },
        {
            path: '/main/fornecedor',
            element: <Fornecedor />
        },
        {
            path: '/main/entrada',
            element: <EntradaEstoque />
        },
        {
            path: '/main/estoque/entrada/cadastro',
            element: <NovaEntrada />
        },
        {
            path: '/main/estoque/entrada/:id',
            element: <EditarEntrada />
        },
        {
            path: '/main/baixa-manual',
            element: <BaixaManual />
        },
        {
            path: '/main/entrada-manual',
            element: <EntradaManual />
        },
        {
            path: '/main/transferencia',
            element: <TransferenciaManual />
        },
        {
            path: '/main/saldo-geral',
            element: <SaldoGeral />
        },
        {
            path: '/main/kardex',
            element: <Kardex />
        },
        {
            path: '/main/solicitacao',
            element: <SolicitacaoEstoque />
        },
        {
            path: '/main/nova-solicitacao',
            element: <NovaSolicitacao />
        },
        {
            path: '/main/solicitacao/:id',
            element: <EditarSolicitacao />
        },
        {
            path: '/main/separacao/:id',
            element: <SeparacaoDeMaterial />
        },
        {
            path: '/main/separacao-estoque',
            element: <Separacao />
        },
        {
            path: '/main/itens-separados/:id',
            element: <PrimeiroNivel />
        },
        {
            path: '/main/liberacao',
            element: <LiberacaoMaterial />
        },
        {
            path: '/main/liberacao/formulario/:id',
            element: <FormularioLiberacao />
        },
        {
            path: '/main/liberacao/finalizancao/:id',
            element: <FinalSeparation />
        },
        {
            path: '/main/relatorios/solicitacao',
            element: <RelatorioSolicitacao />
        }
    ]
};

export default MainRoutes;
